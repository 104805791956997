import {StatusBar} from 'expo-status-bar';
import {Image, SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import { SelectList } from 'react-native-dropdown-select-list'
import * as Yup from "yup";
import {useRef, useState} from "react";
import {Dialog, DialogContent, DialogHeader, ListItem, Provider, Switch, TextInput} from "@react-native-material/core";
import {Formik} from "formik";

export default function App() {
    const FormValidationSchema = Yup.object().shape({

        first_name: Yup.string()
            .min(2, "Минимум 2 буквы")
            .max(50, "Максимум 50 букв")
            .required("Введите имя"),
        last_name: Yup.string()
            .min(2, "Минимум 2 буквы")
            .max(50, "Максимум 50 букв")
            .required("Введите фамилию"),
        patronymic: Yup.string()
            .max(50, "Максимум 50 букв"),
        city: Yup.string()
            .max(50, "Максимум 50 букв"),
        birth_date: Yup.string()
            .required("Заполните дату рождения"),
        address: Yup.string()
            .min(5, "Минимум 5 символов")
            .required("Введите адрес прописки"),
        email: Yup.string()
            .email("Неверный адрес электронной почты"),
        phone: Yup.string()
            .min(10, "Минимум 10 цифр")
            .max(11, "Максимум 11 цифр")
            .matches(/^\+?[7-9][0-9]{9,10}$/, "Не корректный телефон")
            .required("Введите номер телефона"),
        approve: Yup.boolean()
            .oneOf([true], 'Необходимо соглашение')
    });

    const formRef = useRef();
    const [isApproval, setApproval] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [district, setDistrict] = useState('Кировский');
    const [activity, setActivity] = useState({
        "student": false,
        "worker": false,
        "unemployed": false,
        "retiree": false
    });
    const [how, setHow] = useState({
        "social": false,
        "smi": false,
        "friends": false,
        "other": false
    });
    const districts = [
        {key:0, value:'Кировский'},
        {key:1, value:'Ленинский'},
        {key:2, value:'Трусовский'},
        {key:3, value:'Советский'},
        {key:4, value:'Ахтубинский'},
        {key:5, value:'Володарский'},
        {key:6, value:'Енотаевский'},
        {key:7, value:'Икрянинский'},
        {key:8, value:'Камызякский'},
        {key:9, value:'Красноярский'},
        {key:10, value:'Лиманский'},
        {key:11, value:'Наримановский'},
        {key:12, value:'Приволжский'},
        {key:13, value:'Харабалинский'},
        {key:14, value:'Черноярский'},
        {key:15, value:'ЗАТО Знаменск'}
    ]

    const initFormValues = {
        first_name: '',
        last_name: '',
        patronymic: '',
        birth_date: '',
        city: 'Астрахань',
        address: '',
        email: '',
        phone: '',
        other_text: '',
        approve: false
    }


    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.headerContainer}>
                <View style={styles.leftImageContainer}>
                    <Image
                        style={styles.logoImage}
                        source={require("./assets/bear_logo_with_text.png")}
                        resizeMode={'contain'}
                    />
                </View>
                <View style={styles.rightImageContainer}>
                    <Image
                        style={styles.logoImage}
                        source={require("./assets/qr.png")}
                        resizeMode={'contain'}
                    />
                </View>
            </View>
            { !dialogVisible && <ScrollView style={styles.scrollViewStyle}>
                <Text style={styles.mainText}>
                    Заполните контактную информацию
                </Text>
                <Formik
                    initialValues={initFormValues}
                    innerRef={formRef}
                    validationSchema={FormValidationSchema}
                    onSubmit={(values, {resetForm}) => {
                        let url = "https://master.astrakhan.ru/stim/edros/";
                        let headers = new Headers();
                        let fd = new FormData();

                        for (let key in formRef.current.values) {
                            fd.append(key, formRef.current.values[key]);
                        }
                        for (let key in activity) {
                            fd.append(key, activity[key]);
                        }
                        for (let key in how) {
                            fd.append(key, how[key]);
                        }
                        let requestOptions = {
                            mode: 'no-cors',
                            referrer: url,
                            method: 'POST',
                            headers: headers,
                            body: fd,
                            redirect: 'follow'
                        };
                        console.log(formRef)
                        fetch(url, requestOptions)
                            .then(result => {
                                setDialogVisible(true)
                                setTimeout(() => {
                                    resetForm({values: initFormValues})
                                    formRef.current?.resetForm()
                                    setActivity({
                                        "student": false,
                                        "worker": false,
                                        "unemployed": false,
                                        "retiree": false
                                    })
                                    setHow({
                                        "social": false,
                                        "smi": false,
                                        "friends": false,
                                        "other": false
                                    })
                                    setDistrict('Кировский')
                                    setApproval(false)
                                    setDialogVisible(false)
                                    location.href = "https://astrakhan.su/news/"
                                }, 3000)
                            })
                            .catch(error => console.log('error', error));
                    }}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          setFieldTouched,
                          isValid,
                          handleSubmit
                      }) => (
                        <View>
                            <View style={styles.oneRow}>
                                <View style={styles.colum}>
                                    <Text style={styles.labelText}>
                                        ФАМИЛИЯ
                                    </Text>
                                    <TextInput
                                        nativeID="last_name"
                                        variant="outlined"
                                        placeholder="Иванов"
                                        keyboardType="default"
                                        value={values.last_name}
                                        onBlur={handleBlur('last_name')}
                                        onChangeText={handleChange('last_name')}
                                    />
                                    {(errors.last_name && touched.last_name) && (
                                        <Text style={styles.errorText}>{errors.last_name}</Text>
                                    )}
                                </View>
                            </View>
                            <View style={styles.oneRow}>
                                <View style={styles.column}>
                                    <Text style={styles.labelText}>
                                        ИМЯ
                                    </Text>
                                    <TextInput
                                        nativeID="first_name"
                                        variant="outlined"
                                        placeholder="Иван"
                                        keyboardType="default"
                                        value={values.first_name}
                                        onBlur={handleBlur('first_name')}
                                        onChangeText={handleChange('first_name')}
                                    />
                                    {(errors.first_name) && (
                                        <Text style={styles.errorText}>{errors.first_name}</Text>
                                    )}
                                </View>
                            </View>
                            <View style={styles.oneRow}>
                                <View style={styles.column}>
                                    <Text style={styles.labelText}>
                                        ОТЧЕСТВО
                                    </Text>
                                    <TextInput
                                        variant="outlined"
                                        keyboardType="default"
                                        placeholder="Иванович"
                                        value={values.patronymic}
                                        onBlur={handleBlur('patronymic')}
                                        onChangeText={handleChange('patronymic')}
                                    />
                                    {(errors.patronymic && touched.patronymic) && (
                                        <Text style={styles.errorText}>{errors.patronymic}</Text>
                                    )}
                                </View>
                            </View>
                            <View style={styles.oneRow}>
                                <View style={styles.column}>
                                    <Text style={styles.labelText}>
                                        ДАТА РОЖДЕНИЯ
                                    </Text>
                                    <TextInput
                                        variant="outlined"
                                        keyboardType="numbers-and-punctuation"
                                        value={values.birth_date}
                                        placeholder="дд.мм.гггг"
                                        onBlur={handleBlur('birth_date')}
                                        onChangeText={handleChange('birth_date')}
                                    />
                                    {(errors.birth_date && touched.birth_date) && (
                                        <Text style={styles.errorText}>{errors.birth_date}</Text>
                                    )}
                                </View>
                            </View>
                            <View style={styles.oneRow}>
                                <View style={styles.column}>
                                    <Text style={styles.labelText}>
                                        ГОРОД ПРОЖИВАНИЯ
                                    </Text>
                                    <TextInput
                                        variant="outlined"
                                        keyboardType="default"
                                        value={values.city}
                                        placeholder="Астрахань"
                                        onBlur={handleBlur('city')}
                                        onChangeText={handleChange('city')}
                                    />
                                </View>
                            </View>
                            <View style={styles.oneRow}>
                                <View style={styles.column}>
                                    <Text style={styles.labelText}>
                                        АДРЕС ПРОЖИВАНИЯ
                                    </Text>
                                    <TextInput
                                        variant="outlined"
                                        keyboardType="default"
                                        placeholder="Сенная, д.33"
                                        value={values.address}
                                        onBlur={handleBlur('address')}
                                        onChangeText={handleChange('address')}
                                    />
                                    {(errors.address && touched.address) && (
                                        <Text style={styles.errorText}>{errors.address}</Text>
                                    )}
                                </View>
                            </View>
                            <View style={styles.oneRow}>
                                <View style={styles.column}>
                                    <Text style={styles.labelText}>
                                        МОБИЛЬНЫЙ ТЕЛЕФОН
                                    </Text>
                                    <TextInput
                                        variant="outlined"
                                        placeholder="9375001245"
                                        keyboardType="phone-pad"
                                        value={values.phone}
                                        onBlur={handleBlur('phone')}
                                        onChangeText={handleChange('phone')}
                                    />
                                    {(errors.phone && touched.phone) && (
                                        <Text style={styles.errorText}>{errors.phone}</Text>
                                    )}
                                </View>
                            </View>
                            <View style={styles.oneRow}>
                                <View style={styles.column}>
                                    <Text style={styles.labelText}>
                                        ЭЛЕКТРОННАЯ ПОЧТА
                                    </Text>
                                    <TextInput
                                        variant="outlined"
                                        placeholder="my.email@yandex.ru"
                                        keyboardType="email-address"
                                        value={values.email}
                                        onBlur={handleBlur('email')}
                                        onChangeText={handleChange('email')}
                                    />
                                    {(errors.email && touched.email) && (
                                        <Text style={styles.errorText}>{errors.email}</Text>
                                    )}
                                </View>
                            </View>
                            <View style={styles.oneRow}>
                                <View style={styles.column} nativeID="activity">
                                    <Text style={styles.labelText}>
                                        СФЕРА ДЕЯТЕЛЬНОСТИ
                                    </Text>
                                    <ListItem
                                        title="Студент"
                                        disableUnderline
                                        trailing={
                                            <Switch
                                                value={activity.student}
                                                onValueChange={() => {
                                                    setActivity({...activity, student: !activity.student})
                                                }}
                                            />
                                        }
                                        onPress={() => setActivity({...activity, student: !activity.student})}
                                    />
                                    <ListItem
                                        title="Трудоустроенный"
                                        disableUnderline
                                        trailing={
                                            <Switch
                                                value={activity.worker}
                                                onValueChange={() => {
                                                    setActivity({...activity, worker: !activity.worker})
                                                }}
                                            />
                                        }
                                        onPress={() => setActivity({...activity, worker: !activity.worker})}
                                    />
                                    <ListItem
                                        title="Безработный"
                                        disableUnderline
                                        trailing={
                                            <Switch
                                                value={activity.unemployed}
                                                onValueChange={() => {
                                                    setActivity({...activity, unemployed: !activity.unemployed})
                                                }}
                                            />
                                        }
                                        onPress={() => setActivity({...activity, unemployed: !activity.unemployed})}
                                    />
                                    <ListItem
                                        title="Пенсионер"
                                        disableUnderline
                                        trailing={
                                            <Switch
                                                value={activity.retiree}
                                                onValueChange={() => {
                                                    setActivity({...activity, retiree: !activity.retiree})
                                                }}
                                            />
                                        }
                                        onPress={() => setActivity({...activity, retiree: !activity.retiree})}
                                    />
                                </View>
                            </View>
                            <View style={styles.oneRow}>
                                <View style={styles.column}>
                                    <Text style={styles.labelText}>
                                        КАК ВЫ УЗНАЛИ О ШТАБЕ ОБЩЕСТВЕННОЙ ПОДДЕРЖКИ?
                                    </Text>
                                    <ListItem
                                        title="Социальные сети"
                                        disableUnderline
                                        trailing={
                                            <Switch
                                                value={how.social}
                                                onValueChange={() => {
                                                    setHow({...how, social: !how.social})
                                                }}
                                            />
                                        }
                                        onPress={() => setHow({...how, social: !how.social})}
                                    />
                                    <ListItem
                                        title="СМИ"
                                        disableUnderline
                                        trailing={
                                            <Switch
                                                value={how.smi}
                                                onValueChange={() => {
                                                    setHow({...how, smi: !how.smi})
                                                }}
                                            />
                                        }
                                        onPress={() => setHow({...how, smi: !how.smi})}
                                    />
                                    <ListItem
                                        title="От друзей или знакомых"
                                        disableUnderline
                                        name="friends"
                                        trailing={
                                            <Switch
                                                value={how.friends}
                                                onValueChange={() => {
                                                    setHow({...how, friends: !how.friends})
                                                }}
                                            />
                                        }
                                        onPress={() => setHow({...how, friends: !how.friends})}
                                    />
                                    <ListItem
                                        title="Другое"
                                        disableUnderline
                                        trailing={
                                            <Switch
                                                value={how.other}
                                                onValueChange={() => {
                                                    setHow({...how, other: !how.other})
                                                }}
                                            />
                                        }
                                        onPress={() => setHow({...how, other: !how.other})}
                                    />
                                    <TextInput
                                        name="other_text"
                                        value={values.other_text}
                                        variant="outlined"
                                        keyboardType="default"
                                        onChangeText={handleChange('other_text')}
                                        disabled={!how.other}
                                    />
                                </View>
                            </View>
                            {(errors.approve && touched.approve) && (
                                <Text style={styles.errorText}>{errors.approve}</Text>
                            )}
                            <View style={styles.oneRow}>
                                <View style={styles.row}>
                                    <Switch
                                        value={isApproval}
                                        onValueChange={() => {
                                            setApproval(!isApproval)
                                            formRef.current.setFieldValue('approve', !values.approve)
                                        }}
                                    />
                                    <Text
                                        style={styles.approvalText}
                                        onPress={() => {
                                            setApproval(!isApproval)
                                            formRef.current.setFieldValue('approve', !values.approve)
                                        }}
                                    >
                                        Я, как субъект персональных данных, в соответствии с требованиями Федерального
                                        закона от
                                        27.07.2006 № 152-ФЗ "О персональных данных", даю свое согласие на обработку
                                        персональных
                                        данных,
                                        указанных в анкете.
                                    </Text>
                                </View>
                            </View>
                            <TouchableOpacity
                                style={[styles.saveButton, {backgroundColor: isValid ? "#15589B" : "#436F9B"}]}
                                onPress={handleSubmit}
                            >
                                <Text style={styles.saveButtonText}>Сохранить!!!</Text>
                            </TouchableOpacity>

                        </View>
                    )}
                </Formik>
                <StatusBar style="auto"/>
            </ScrollView>}
            <Provider style={{justifyContent: "flex-end"}}>
                <Dialog visible={dialogVisible}>
                    <DialogHeader title="Спасибо"/>
                    <DialogContent>
                        <Text style={{textAlign: "center", fontSize: 12, fontWeight: "bold"}}>
                            Ваша Анкета успешно отправлена!
                        </Text>
                        <Text style={{textAlign: "center", paddingTop: 10}}>
                            А пока предлагаем вам ознакомиться с региональными новостями.
                        </Text>
                    </DialogContent>
                </Dialog>
            </Provider>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: "100%"
    },
    scrollViewStyle: {
        marginTop: "1%",
        paddingHorizontal: "5%",
        paddingBottom: "3%",
        height: "100%"
    },
    mainText: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#000000"
    },
    headerContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        justifyContent: "space-between",
        width: "100%",
        height: 150,
    },
    leftImageContainer: {
        padding: '5px',
        width: "50%",
        height: "100%",
        alignItems: "center"
    },
    rightImageContainer: {
        width: "50%",
        height: "100%",
        alignItems: "flex-end"
    },
    logoImage: {
        width: "90%",
        height: "100%"
    },
    headerText: {
        color: "#000000"
    },
    formGroup: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: 15
    },
    col: {
        display: "flex",
        width: "100%"
    },
    pt10: {
        paddingTop: 10
    },
    oneRow: {
        paddingBottom: 12,
        flexDirection: "column"
    },
    column: {
        flex: 1,
        flexDirection: "column",
        paddingVertical: 5
    },
    row: {
        flex: 1,
        flexDirection: "row",
        paddingVertical: 5
    },
    labelText: {
        color: "#2F2B2B",
        fontSize: 12,
        paddingBottom: 5
    },
    errorText: {
        color: "#c01616",
        fontSize: 12,
    },
    approvalText: {
        paddingLeft: 10
    },
    saveButton: {
        width: "100%",
        alignItems: "center",
        paddingVertical: 20
    },
    saveButtonText: {
        color: "#FFFFFF",
        fontSize: 24
    },
    textInput: {
        fontSize: 18,
        color: "#000000"
    },
    calendarWrapper: {
        position: "absolute",
        zIndex: 9999
    },
    selectBox: {
        height: 55,
        paddingLeft: 15,
        overflow: "hidden",
        width: "auto",
        borderRadius: 5
    },
    selectTextInput: {
        fontSize: 16,
        paddingTop: 5,
        color: "#999999"
    }
});
